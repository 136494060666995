<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                Products
                <div class="form-group ml-auto mb-0" style="width: 195px">
                    <input
                        type="text"
                        class="form-control form-control-sm form-control form-control-sm my-auto"
                        v-model="searchText"
                        placeholder="Search"
                    />
                </div>
                <router-link :to="{ name: 'product-add' }" v-if="hasPermissions('modify:products')">
                    <button type="button" class="btn btn-sm btn-light ml-3">Add</button>
                </router-link>
            </div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <div v-else>
                    <div v-if="!products.length">No information to display</div>
                    <div v-else>
                        <table class="table table-sm table-borderless mb-0" id="products-table">
                            <thead>
                                <tr>
                                    <th width="50%">Name</th>
                                    <th>In stock</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in products" :key="product.id">
                                    <td>
                                        <img :src="product.image_url || defaultImage"
                                             class="product-image mr-3"
                                             alt="Product Image">
                                        {{ product.name }}
                                    </td>
                                    <td>{{ product.quantity }}</td>
                                    <td>
                                        <button
                                            type="button"
                                            data-toggle="dropdown"
                                            class="btn btn-link text-secondary p-1"
                                        >
                                            <i class="fas fa-1x fa-ellipsis-h"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <router-link :to="{ name: 'product-edit', params: {id: product.id} }"
                                                    v-if="hasPermissions('modify:products')"
                                                    class="dropdown-item">
                                                Edit
                                            </router-link>
                                            <router-link :to="{ name: 'product-fields', params: {id: product.id} }"
                                                    class="dropdown-item">
                                                Data fields
                                            </router-link>
                                            <router-link :to="{ name: 'product-items', params: { id: product.id }}"
                                                    v-if="hasPermissions('read:items')"
                                                    class="dropdown-item">
                                                View items
                                            </router-link>
                                            <a href="#items-import-modal" data-toggle="modal"
                                                    v-if="hasPermissions('modify:items')"
                                                    class="dropdown-item"
                                                    @click="currentProduct = product">
                                                Import items
                                            </a>
                                            <a href="#print-labels-modal" data-toggle="modal"
                                                    v-if="hasPermissions('modify:items')"
                                                    class="dropdown-item"
                                                    @click="currentProduct = product">
                                                Print labels
                                            </a>
                                            <a href="#delete-object-modal" data-toggle="modal"
                                                    v-if="hasPermissions('modify:products')"
                                                    class="dropdown-item text-danger"
                                                    @click="currentProduct = product">
                                                Delete
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <DeleteObjectModal
            @delete-object="deleteProduct"
            :currentObject="currentProduct"
            :type="'product'"
        />
        <PrintLabelsModal :currentProduct="currentProduct" />
        <ItemsImportModal :currentProduct="currentProduct" />
    </div>
</template>

<script>
import { DEFAULT_IMAGE } from '../../constants';
import { getProducts } from '../../services/ProductService';
import dataTableMixin from '../../mixins/dataTable';
import Spinner from 'vue-simple-spinner';
import ItemsImportModal from '../../components/modals/ItemsImportModal.vue';
import DeleteObjectModal from '../../components/modals/DeleteObjectModal.vue';
import PrintLabelsModal from '../../components/modals/PrintLabelsModal.vue';
import { hasPermissions } from '../../helpers/permissionUtils';

export default {
    name: 'ProductList',
    components: {
        ItemsImportModal,
        DeleteObjectModal,
        PrintLabelsModal,
        Spinner
    },
    mixins: [dataTableMixin],
    data() {
        return {
            products: [],
            currentProduct: {},
            defaultImage: DEFAULT_IMAGE,
            loading: false
        };
    },
    async mounted() {
        await this.getProducts();
        if (!this.products.length) return;
        this.createDataTable('#products-table');
    },
    methods: {
        async getProducts() {
            try {
                this.loading = true;
                const response = await getProducts();
                this.products = response.data;
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        deleteProduct(id) {
            this.products = this.products.filter(p => p.id !== id);
        },
        hasPermissions,
    }
};
</script>

<style scoped>
.table {
    table-layout: fixed;
}

.table td {
    vertical-align: middle;
}

.product-image {
    width: 50px;
    max-height: 50px;
    border-radius: 5px;
}
</style>

