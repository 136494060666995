<template>
    <div class="modal fade" id="items-import-modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <b
                        class="modal-title"
                        id="addProductTitle"
                    >Import items for {{ product.name }}</b>
                </div>
                <div class="modal-body pb-4">
                    <form @submit.prevent="startProductItemsImport" id="start-import-form">
                        <div class="row">
                            <div class="form-group col">
                                <label class="col-form-label" for="name">Amount</label>
                                <input
                                    class="form-control form-control-sm"
                                    type="number"
                                    min="1"
                                    max="100"
                                    required
                                    v-model="amount"
                                />
                            </div>
                            <div class="form-group col">
                                <label class="col-form-label" for="name">Cost</label>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input
                                            class="form-control"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            required
                                            @change="formatCost"
                                            v-model="itemCost"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="form-group custom-switch mb-0 mr-auto">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="print-labels"
                            v-model="printLabels"
                        />
                        <label for="print-labels" class="custom-control-label">Print labels</label>
                    </div>

                    <button
                        type="button"
                        class="btn btn-sm btn-light"
                        data-dismiss="modal"
                    >Close</button>
                    <SubmitButton :form="'start-import-form'" :btnDisabled="loading" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { printLabels } from '../../services/ProductService';
import SubmitButton from '../../components/shared/SubmitButton.vue';

export default {
    name: 'ItemsImportModal',
    components: {
        SubmitButton
    },
    props: {
        currentProduct: Object
    },
    data() {
        return {
            product: {},
            loading: false,
            amount: '',
            itemCost: 0,
            printLabels: true
        };
    },
    watch: {
        currentProduct() {
            this.product = { ...this.currentProduct };
            this.itemCost = (this.product.default_cost / 100).toFixed(2);
        }
    },
    methods: {
        async startProductItemsImport() {
            try {
                if (this.printLabels) {
                    this.loading = true;
                    await printLabels(this.amount, this.currentProduct.id);
                }
                localStorage.setItem(
                    'importSession',
                    JSON.stringify({
                        productName: this.product.name,
                        importAmount: this.amount,
                        itemCost: this.itemCost * 100,
                        totalScanned: 0
                    })
                );
                this.$router.push({
                    name: 'product-import-items',
                    params: { id: this.product.id }
                });
            } catch (error) {
                console.log(error);
            }
            $('#import-items-modal').modal('hide');
            $('.modal-backdrop').remove();
            this.loading = false;
        },
        formatCost() {
            this.itemCost = parseFloat(this.itemCost).toFixed(2);
        }
    }
};
</script>

<style scoped>
    .input-group-prepend {
        height: 31px;
    }
</style>