<template>
    <div class="modal fade" id="print-labels-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <b class="modal-title" id="addProductTitle">Print labels for {{ currentProduct.name }}</b>
                </div>
                <div class="modal-body">
                    <form id="print-labels-form" @submit.prevent="printLabels">
                        <div class="form-group">
                            <label class="col-form-label" for="name">Amount</label>
                            <input
                                class="form-control form-control-sm"
                                type="number"
                                min="1"
                                max="100"
                                required
                                v-model="amount"
                            />
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-light" data-dismiss="modal">Close</button>
                    <SubmitButton :form="'print-labels-form'" :btnDisabled="printing" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { printLabels } from '../../services/ProductService';
import SubmitButton from '../shared/SubmitButton.vue';

export default {
    name: 'PrintLabelsModal',
    components: {
        SubmitButton
    },
    props: {
        currentProduct: Object
    },
    data() {
        return {
            amount: '',
            printing: false
        };
    },
    methods: {
        async printLabels() {
            try {
                this.printing = true;
                await printLabels(this.amount, this.currentProduct.id);
            } catch (error) {
                console.log(error);
            }
            $('#print-labels-modal').modal('hide');
            this.amount = '';
            this.printing = false;
        }
    }
};
</script>
